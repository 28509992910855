<template>
	<page-index page="products"></page-index>
</template>

<script>
	import pageIndex from "@/components/PageIndex.vue";
	export default {
		name: "ProductsIndex",
		components: { pageIndex }
	}
</script>